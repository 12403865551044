
import { defineComponent } from '@nuxtjs/composition-api';
import { analyticsMixin } from '@/mixins/analytics';
import { getEsFooterContent, getEsNavBarAccountContent, getEsNavBarGlobalContent } from '@energysage/es-vue-base';

export default defineComponent({
    name: 'DefaultLayout',
    mixins: [analyticsMixin],
    computed: {
        footerContent() {
            return getEsFooterContent();
        },
        navAccountContent() {
            return getEsNavBarAccountContent();
        },
        navGlobalContent() {
            return getEsNavBarGlobalContent();
        },
    },
});
