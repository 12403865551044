import { defineNuxtPlugin } from '@nuxtjs/composition-api';

export default defineNuxtPlugin(async ({ $axios, $cookies }, inject) => {
    const sharedConfig = {
        headers: { Authorization: `Bearer ${$cookies.get('flash.id_token')}` },
    };

    // Create a custom axios instance for profiles API
    const profilesApi = $axios.create({
        ...sharedConfig,
        baseURL: process.env.PROFILES_API_BASE_URL,
    });

    // Create a custom axios instance for equipment API
    const equipmentApi = $axios.create({
        baseURL: process.env.EQUIPMENT_API_BASE_URL,
    });

    // Create a custom axios instance for manufacturer API
    const manufacturerApi = $axios.create({
        baseURL: process.env.MANUFACTURER_API_BASE_URL,
    });

    // Create a custom axios instance for external equipment API
    const externalEquipmentApi = $axios.create({
        ...sharedConfig,
        baseURL: process.env.EXTERNAL_EQUIPMENT_API_BASE_URL,
    });

    // Create a custom axios instance for es site API
    const esSiteApi = $axios.create({
        baseURL: process.env.ES_DOMAIN,
    });

    // Inject to context as $profilesApi, $equipmentApi, $externalEquipmentApi and $esSiteApi
    inject('profilesApi', profilesApi);
    inject('equipmentApi', equipmentApi);
    inject('externalEquipmentApi', externalEquipmentApi);
    inject('esSiteApi', esSiteApi);
    inject('manufacturerApi', manufacturerApi);
});
